import { Button, Dialog as DialogMUI, DialogActions, DialogContent, DialogContentProps, DialogProps, DialogTitle, IconButton } from '@mui/material';
import { Close, FullscreenExitOutlined, FullscreenOutlined } from '@mui/icons-material';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ActionRow, OccupyFreeSpace } from './ActionRow';
import { Tooltip } from './Tooltip';
import isHotkey from 'is-hotkey';

interface DialogPropsX extends Omit<DialogProps, "onClose" | "open"> {
    dialogTitle: ReactNode;
    children?: ReactNode;
    saveLabel?: ReactNode;
    save: () => void;
    noSave?: boolean;
    isOpen: boolean;
    close: () => void;
    noFullscreen?: boolean;
    closeButtonTop?: boolean;
    noSubmitOnEnter?: boolean;
    submitOnModEnter?: boolean;
}

interface DialogPropsXX extends Omit<DialogPropsX, "save" | "saveLabel" | "noSave"> {
    actions?: ReactNode;
    titleActions?: ReactNode;
    fixedMaxHeight?: boolean;
    contentWrapper?: React.ComponentType<DialogContentProps>;
}

export const Dialog = (props: DialogPropsXX) => {
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

    const Content = props.contentWrapper || DialogContent;

    const paperProps = { ...(props.PaperProps || {}) };
    const paperStyle = paperProps.style || {};
    if(props.fixedMaxHeight) {
      paperProps.style = { ...paperStyle, minHeight: "calc(100% - 64px)" };
    }

    return (
        <DialogMUI fullScreen={isFullscreen} fullWidth {...props} PaperProps={paperProps} open={props.isOpen} onClose={() => props.close()}>
            <DialogTitle>
                <ActionRow firstItemNoMargin itemMarginTop="0" lastItemMarginRight="0">
                    {props.dialogTitle}
                    
                    <OccupyFreeSpace />
                    {props.titleActions}
                    {!props.noFullscreen &&
                        <Tooltip text_id="common.fullscreen">
                            <IconButton size="small" onClick={() => setIsFullscreen(x => !x)}>
                                {isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                            </IconButton>
                        </Tooltip>}
                    {!!props.closeButtonTop &&
                      <IconButton size="small" onClick={() => props.close()}>
                        <Close />
                      </IconButton>}
                </ActionRow>
            </DialogTitle>

            <Content>
                {props.children}
            </Content>

            {props.actions && <DialogActions>
                {props.actions}
            </DialogActions>}
        </DialogMUI>
    );
}

export const SimpleDialog = (props: DialogPropsX) => {
    return (
        <Dialog {...props}
            onKeyUp={e => {
              if(props.submitOnModEnter) {
                if(isHotkey("mod+enter", e)) {
                  props.save();
                }
              } else if(!props.noSubmitOnEnter && (isHotkey("enter", e) || isHotkey("mod+enter", e))) {
                props.save();
              }
            }}
            actions={<>
                <Button onClick={() => props.close()}><FormattedMessage id="common.cancel" /></Button>
                {!props.noSave && <Button color="primary" variant="contained" onClick={() => props.save()}>{props.saveLabel || <FormattedMessage id="common.save" />}</Button>}
            </>}>
            {props.children}
        </Dialog>
        
    );
}

export interface DialogState {
    isOpen: boolean;
    open: () => void;
    close: () => void;
}

export const useDialogState = (isOpenDefault?: boolean) => {
    const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault || false);

    return {
        isOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
    }
}

export interface ItemDialogState<T> extends Omit<DialogState, "open"> {
    item: T | null;
    open: (item: T) => void;
    update: (changes: Partial<T>) => void;
}

export const useItemDialogState = <T,>(defaultItem: T | null = null): ItemDialogState<T> => {
    const [item, setItem] = useState<T | null>(defaultItem);
    
    return {
        item,
        isOpen: item !== null,
        open: (item) => setItem(item),
        update: (changes: Partial<T>) => setItem(x => x ? ({ ...x, ...changes }) : x),
        close: () => setItem(null),
    }
}
