import { useMemo } from "react";
import { useLoadedData } from "../../hooks/useLoadedData";
import { Program } from "./types";
import { FieldSchema, FieldType } from "../../hooks/useSchema";
import { useApiConfiguration } from "../ApiConfguration";

export const useRobudPrograms = () => {
  const { robudApiPath } = useApiConfiguration();
  const data = useLoadedData<Program[]>(`${robudApiPath}/program`, [], !!robudApiPath);

  const selectorSchema: FieldSchema = useMemo(() => {
    const options = data.data.map(p => ({ value: p._id, label: p.title }));
    return {
      type: FieldType.select,
      values: options,
      valueDict: options.reduce<Record<number, string>>((r,v) => { r[v.value] = v.label; return r; }, {}),
    }
  }, [data.data]);

  return {
    ...data,
    selectorSchema,
    isAvailable: !!robudApiPath,
  }
}
