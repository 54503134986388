import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { DisplayProps, expandProps, FormControlProps } from './FormControlProps';
import NumberFormat from 'react-number-format';
import { useFormats } from './FormatsContext';
import { Hint } from './Hint';

const DefaultPrecision = 2;

const nParseInt = (v: string) => {
    const parsed = Number.parseInt(v);
    return isNaN(parsed) ? null : parsed;
}

const nParseFloat = (v: string) => {
    const parsed = Number.parseFloat(v);
    return isNaN(parsed) ? null : parsed;
}

export const nParseDecimal = (v: string) => {
    const parsed = Number.parseFloat(v);
    return isNaN(parsed) ? null : parsed;
}

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    value: any;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
    const formats = useFormats();

    const thousandSeparator = formats.thousandSeparator as string;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={!thousandSeparator || thousandSeparator === "" ? false : thousandSeparator}
            isNumericString
            onCopy={(e) => navigator.clipboard.writeText(other.value ? other.value.toString().replace(thousandSeparator, '') : '')}
        />
    );
}

export const NumberEdit = (props: FormControlProps) => {
    const { label, value, controlProps, field, row, error, onChange, extraProps } = expandProps(props);
    const parser = extraProps?.is_float ? nParseFloat : nParseInt;

    return <TextField
        {...controlProps}
        label={label}
        value={value}
        error={error}
        size="small"
        margin="none"
        fullWidth
        onChange={e => {
            const val = parser(e.target.value);
            if(val !== row[field]) {
                onChange(row, { [field]: val });
            }
        }}
        InputProps={{
            inputComponent: NumberFormatCustom as any,
            ...(controlProps.InputProps || {}),
            readOnly: controlProps.readOnly,
        }}
        helperText={<Hint schema={props.schema} wrapper={React.Fragment} extraProps={extraProps} />}
    />;
}

export const DecimalEdit = (props: FormControlProps) => {
    const { label, value, controlProps, onChange, field, row, error: validationError, extraProps } = expandProps(props);
    const [error, setError] = useState<boolean>(false);
    const [innerValue, setInnerValue] = useState<string | null>('');

    const precision = props.extraProps?.precision || DefaultPrecision;

    useEffect(() => {
        setInnerValue(value ? nParseDecimal(value)?.toFixed(precision) : value);
    }, [value, precision]);

    const applyNewValue = (inner: string | null) => {
        if (!inner) {
            onChange(row, { [field]: null });
            setError(false);
            return;
        }

        const parsed = nParseDecimal(inner);

        if (parsed || parsed === 0) {
            setError(false);
            onChange(row, { [field]: inner });
            return;
        } else {
            onChange(row, { [field]: null });
            setError(true);
            return;
        }
    }

    const onLeave = () => {
        applyNewValue(innerValue);
    }

    const isImmediate = !!extraProps?.immediate;

    return <TextField
        {...controlProps}
        label={label}
        value={innerValue}
        error={error || validationError}
        size="small"
        margin="none"
        fullWidth
        onChange={e => {
            setInnerValue(e.target.value);
            if(isImmediate) {
                applyNewValue(e.target.value);
            }
        }}
        onBlur={onLeave}
        InputProps={{
            inputComponent: NumberFormatCustom as any,
            ...(controlProps.InputProps || {}),
            readOnly: controlProps.readOnly,
        }}
    />;
}

export const NumberDisplay = (props: DisplayProps) => {
    const { row, field } = props;
    
    const value = row[field];
    const precision = (props.config as any)?.precision;
    const valueTransformed = precision !== undefined && value !== null && value !== undefined ?
        (value as number).toFixed(precision) : value;
    
    return valueTransformed || "";
}
