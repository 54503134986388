import React from 'react';
import { IconButton } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import { Form, LoadingIndicator, OccupyFreeSpace, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { BasicMenuEditor } from '../../toolympus/components/primitives/BasicMenuEditor';
import { useSettings } from "../../toolympus/api/useSettings"
import { MenuItem } from "../../toolympus/components/primitives/BasicMenuEditor";

interface EpamgSettings {
    menu: MenuItem[];
}

export const useEpamgSettingsEdit = () => {
    const data = useSettings<EpamgSettings>("/api/epamg/settings", { menu: [] });
    

    return {
      ...data,
    }
}


export const SettingsEpamg = () => {
    const data = useEpamgSettingsEdit();

    useSaveable(data);

    const tabs = useTabsState([
        ["menu", "Main menu"],
    ], "menu", "tab");

    return (
        <Form title="Site Settings"
            headerItems={<>
                <OccupyFreeSpace />
                {data.isLoading && <LoadingIndicator />}
                {data.hasChanges && <IconButton size="small" onClick={() => data.save()}><SaveOutlined /></IconButton>}
            </>}>
            <TabsHeader state={tabs} noMargin />

            <TabPanel state={tabs.forTab("menu")}>
              <BasicMenuEditor
                menu={(data.settings?.menu || [])}
                update={v => data.update({ menu: v })}
                />
            </TabPanel>
        </Form>
    );
}
