import { Button, Checkbox, IconButton, Typography } from '@mui/material';
import { Cancel, CheckCircle, GetAppOutlined } from '@mui/icons-material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ActionRow, ButtonOptionPicker, Dialog, DownloadFileLink, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SearchField, Tooltip, useCopyText } from '../primitives';
import { FieldSettingsPopupButton, TableForFields } from '../schemed';
import { ConfigurableFormSubmissionReviewPopup } from './ConfigurableFormSubmissionReviewPopup';
import { FormSubmission } from './types';
import { ConfigurableFormSubmissionsData } from './useConfigurableFormSubmissions';

interface Props {
    data: ConfigurableFormSubmissionsData;
}

export const ConfigurableFormSubmissions = (props: Props) => {
    const { data } = props;
    const copyText = useCopyText();

    return (
        <>
            <ActionRow alignItems="end" lastItemMarginRight='0' firstItemNoMargin>
                <ButtonOptionPicker
                    options={[
                        [<FormattedMessage id="forms.page.submission_view.all" />, "form"],
                        [<FormattedMessage id="forms.page.submission_view.new" />, "form-new"],
                        [<FormattedMessage id="forms.page.submission_view.processed" />, "form-processed"],
                    ]}
                    selected={data.view}
                    setSelected={data.setView}
                    />

                <Typography variant="caption"><FormattedMessage id="forms.page.submissions_total" values={{ total: data.data?.length || 0}}/></Typography>
                <OccupyFreeSpace />

                {(data.form.actions?.manual || []).map(action => (
                  <Button
                    size="small"
                    onClick={() => data.prepareMassAction(action)}
                    disabled={!data.selection.selectedItems.length}
                    >
                    {action.label || action._id}
                  </Button>
                ))}

                <LoadingIndicator sizeVariant='m' style={{ visibility: data.isLoading ? "visible" : "hidden"}} />
                <SearchField
                    filter={data.filter.filter}
                    setFilter={data.filter.setFilter}
                    autoFocus
                    noButton
                    small
                    />
                <IconButton size="small" onClick={() => data.exportSubmissions()}>
                    <GetAppOutlined />
                </IconButton>
                <FieldSettingsPopupButton fieldsSettings={data.fieldsConfig} />
            </ActionRow>

            <TableForFields
                data={data.data}
                fields={data.fieldsConfig.activeFields}
                schema={data.schema}
                onDblClick={row => data.review.open(data.dataRaw.find(s => s._id === row._id) as FormSubmission)}
                fieldHeader={field => {
                  if(field === "selected") {
                    return (
                      <Checkbox
                        size="small"
                        checked={data.selection.isAllSelected}
                        onChange={e => data.selection.toggleAllSelected()}
                        />)
                  }
                }}
                fieldElement={field => {
                    if(field === "selected") {
                      return (row, schema, orig) => (
                        <Checkbox
                          size="small"
                          checked={data.selection.isSelected(row as FormSubmission)}
                          onChange={e => data.selection.updateSelected(row as FormSubmission, e.target.checked)}
                          />);

                    } else if(["created_at", "processing_status"].includes(field)) {
                        return (row, schema, orig) => <PseudoLink2 onClick={() => data.review.open(data.dataRaw.find(s => s._id === row._id) as FormSubmission)}>{orig}</PseudoLink2>;
                    } else {
                        const fieldDef = data.form.fields.find(f => f._id === field);
                        if(fieldDef) {
                            if(["email", "text"].includes(fieldDef.fieldtype)) {
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                return (row, schema, orig) => <a onClick={() => copyText(row[field])}>{orig}</a>;
                            } else if("file" === fieldDef.fieldtype && data.form.files_api_path) {
                                return row => row[field] && <DownloadFileLink downloadFile={() => data.downloadFormFile(row[field])}>
                                    <FormattedMessage id="forms.submission.download" />
                                </DownloadFileLink>
                            }
                        }
                    }
                }}
                />
            <ConfigurableFormSubmissionReviewPopup
                data={{
                    ...data.review,
                    close: () => {
                        data.review.close();
                        data.reload();
                    }
                }}
                form={data.form}
                fields={data.fields}
                schema={data.schema}
                downloadFormFile={data.downloadFormFile}
                remove={data.removeSubmission}
                />
            <MassActionPopup data={data} />
        </>
    );
}

const MassActionPopup = (props: Props) => {
  const massAction = props.data.massAction;
  return (
    <Dialog
      isOpen={!!massAction.actionInfo}
      close={() => !massAction.isRunning && massAction.cancel()}
      dialogTitle={<FormattedMessage id="extras.mass_action.dialog_title" values={{ label: massAction.actionInfo?.label || "" }} />}
      titleActions={<>
        <LoadingIndicator sizeVariant='m' style={{ visibility: massAction.isRunning ? "visible" : "hidden"}} />
      </>}
      actions={<>
        <Button color="primary" variant="contained" disabled={massAction.isRunning || massAction.isFinished} onClick={() => massAction.run()} ><FormattedMessage id="extras.mass_action.execute" /></Button>
        <Button disabled={massAction.isRunning} onClick={() => massAction.cancel()}><FormattedMessage id={massAction.isFinished ? "common.close" : "common.cancel"} /></Button>
      </>}
      fullWidth
      noFullscreen
      maxWidth="lg">
      
      <TableForFields
        data={massAction.actionInfo?.items || []}
        fields={[
          ["status", { label: " " }],
          ...props.data.fieldsConfig.activeFields.filter(([f]) => f !== "selected")
        ]}
        schema={props.data.schema}
        fieldElement={field => {
          if(field === "status") {
            return (row, schema, orig) => {
              const idx = (massAction.actionInfo?.items || []).indexOf(row);
              const status = massAction.status[idx];
              switch(status?.status) {
                case "success":
                  return <CheckCircle htmlColor="#00CC00" />;
                case "error":
                  return <Tooltip text={status.message_id ? undefined : status.message} text_id={status.message_id}><Cancel htmlColor="#CC0000" /></Tooltip>
                case "running":
                  return <LoadingIndicator size="small" />;
                default:
                  return <></>;
              }
            }
          }
          return null;
        }}
        />
    </Dialog>
  )
}