import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NewItemProps } from '../../api/useNewItem';
import { useItemWithControls } from '../../api/useWithControls';
import { Schema } from '../../hooks/useSchema';
import { SimpleDialog, FormGrid } from '../primitives';
import { CreateConfigurableForm, ConfigurableForm } from './types';

interface CreateFormProps extends NewItemProps<CreateConfigurableForm, ConfigurableForm> {
    schema: Schema;
}

export const CreateConfigurableFormPopup = (props: CreateFormProps) => {
    const { isEditing, item, save, cancel, schema, update, errors } = props;
    const { controls } = useItemWithControls({ data: item || {} as CreateConfigurableForm, update, errors }, { schema });

    return (
        <SimpleDialog
            isOpen={isEditing}
            close={cancel}
            dialogTitle={<FormattedMessage id="forms.page.create_form" />}
            save={() => save()}
            noFullscreen
            >
            <FormGrid columns="1fr">
                {controls([
                    ["title"],
                ])}
            </FormGrid>
        </SimpleDialog>);
}
