import React, { PropsWithChildren } from 'react';
import { useSub } from './SubProvider';
import { MediaLibProvider } from '../toolympus/components/medialib';

export const MedialibProviderX = (props: PropsWithChildren<{}>) => {
  const { sub } = useSub();
  return (
    <MediaLibProvider apiPath="/api/media" spaceId={sub || "unk"} urlPrefix="/media">
      {props.children}
    </MediaLibProvider>
  );
}
