import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../frame';
import { ConfigurableFormForm } from './ConfigurableFormForm';
import { ConfigurableFormsList } from './ConfigurableFormsList';
import { ConfigurableFormsConfig } from './types';

interface Props extends ConfigurableFormsConfig {
}

export const ConfigurableFormsRoutes = (props: Props) => {
    let { path } = useRouteMatch();

    const FormComponent = useMemo(() => {
        return () => <ConfigurableFormForm apiPath={props.apiPath} localization={props.localization} />
    }, [props.apiPath, props.localization]);

    const ListComponent = useMemo(() => {
        return () => <ConfigurableFormsList apiPath={props.apiPath} localization={props.localization} />
    }, [props.apiPath, props.localization]);
    
    return (
        <Routes
            routes={[
                { path: `${path}/:id`, component: FormComponent },
                { path: `${path}/`, component: ListComponent },
            ]}
        />
    );
}
