import { Descendant as SlateDescendant } from "slate";
import {
    Document,
    HeadingLevel,
    Paragraph,
    TextRun,
    AlignmentType,
    IParagraphOptions,
    IDocumentTemplate,
    ImportDotx,
    Table,
    TableRow,
    TableCell,
    WidthType,
    BorderStyle,
    ITableBordersOptions,
    ExternalHyperlink,
    TableLayoutType,
    ImageRun,
    ISectionPropertiesOptions,
} from "docx-dotx-hf-fix";
import { Element } from "slate";
import { CustomElement } from "../../../../slate";
import { ParagraphElementType } from "../slate/BlockElements";
import { NumberingConfig } from "./numbering";
import { TableCellElementType, TableElementType, TableRowElementType } from "../plugins/Tables";
import { LinkElementType } from "../plugins/Links";
import { ImageElementType } from "../plugins/InlineImages";

const HeadingLevels = [
    HeadingLevel.TITLE, // won't hit because we don't have h0
    HeadingLevel.HEADING_1,
    HeadingLevel.HEADING_2,
    HeadingLevel.HEADING_3,
    HeadingLevel.HEADING_4,
    HeadingLevel.HEADING_5,
    HeadingLevel.HEADING_6,
]


interface ProcessingContext {
    getImage: (url: string) => ArrayBuffer | null | undefined;
    customProcessors?: NodeProcessor[];
}

export interface NodeProcessor {
    shallProcess: (node: SlateDescendant) => boolean;
    process: (node: any, generator: any, settings: any, ctx: ProcessingContext) => any;
}

const alignments: Record<any, AlignmentType> = {
    "left": AlignmentType.LEFT,
    "right": AlignmentType.RIGHT,
    "center": AlignmentType.CENTER,
    "justify": AlignmentType.JUSTIFIED,
}

export const commonBlockProperties = (n: CustomElement): Partial<IParagraphOptions> => ({
    alignment: n.align ? (alignments[n.align] || undefined) : undefined,
});

export const NoBorders: ITableBordersOptions = {
    top: { style: BorderStyle.NONE },
    bottom: { style: BorderStyle.NONE },
    left: { style: BorderStyle.NONE },
    right: { style: BorderStyle.NONE },
    insideHorizontal: { style: BorderStyle.NONE },
    insideVertical:{ style: BorderStyle.NONE },
}

const getTableColumnsEvenWidths = (table: any): number[] | undefined => {
    const rows = table.children as any[] || [];
    if(!rows || !rows.length) {
        return undefined;
    }
    
    if(table.columns) {
      const widths = (table.columns as string).replace(",", " ").split(/\s+/).map(s => s.trim()).map(s => +s);
      if(widths.length && widths.every(w => !isNaN(w))) {
        return widths.map(x => 1.0 * x);
      }
    }

    const columns = rows[0].children;
    if(!columns || !columns.length) {
        return undefined;
    }
    const nColumns = columns.length;
    return columns.map(() => 100 / nColumns);
}

const processors: NodeProcessor[] = [
    {
        shallProcess: n => (n as any).type === ParagraphElementType,
        process: (n, g, s, ctx) => new Paragraph({
            children: g(n.children || [], s, ctx),
            ...commonBlockProperties(n),
            indent: (n as any).no_indent || s?.no_indent ? { start: 0, firstLine: 0 } : undefined,
        }),
    },
    {
        shallProcess: n => (n as any).type === "ul",
        process: (n, g, s, ctx) => g(n.children || [], { ul: 0 }, ctx),
    },
    {
        shallProcess: n => (n as any).type === "ol",
        process: (n, g, s, ctx) => g(n.children || [], { ol: 0 }, ctx),
    },
    {
        shallProcess: n => (n as any).type === "li",
        process: (n, g, settings, ctx) => new Paragraph({
            children: g(n.children || [], settings, ctx),
            ...commonBlockProperties(n),
            ...(settings?.ul !== undefined ? { bullet: { level: settings.ul }} : {}),
            ...(settings?.ol !== undefined ? { numbering: { reference: NumberingConfig.reference, level: settings.ol }} : {}),
        }),
    },
    {
        shallProcess: n => (n as any).type === TableElementType,
        process: (n, g, s, ctx) => new Table({
            rows: g(
              n.children || [],
              { ...(s || {}), no_indent: (n as any).no_indent ? true : false },
              ctx),
            borders: (n as any).no_border ? NoBorders : undefined,
            layout: (n as any).even_columns ? TableLayoutType.FIXED : undefined,
            columnWidths: (n as any).even_columns ? getTableColumnsEvenWidths(n) : undefined,
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
        }),
    },
    {
        shallProcess: n => (n as any).type === TableRowElementType,
        process: (n, g, s, ctx) => new TableRow({
            children: g(n.children || [], s, ctx),
        }),
    },
    {
        shallProcess: n => (n as any).type === TableCellElementType,
        process: (n, g, s, ctx) => new TableCell({
            children: [new Paragraph({
                children: g(n.children || [], s, ctx),
                ...commonBlockProperties(n),
                indent: (n as any).no_indent || s?.no_indent ? { start: 0, firstLine: 0 } : undefined,
            })],
        }),
    },
    {
        shallProcess: n => (n as any).type === LinkElementType,
        process: (n, g, s, ctx) => new ExternalHyperlink({
            children: g(n.children || [], s, ctx),
            link: n.url,
            ...commonBlockProperties(n),
        }),
    },
    {
        shallProcess: n => (n as any).type === ImageElementType,
        process: (n, g, s, ctx) => {
            const imageData = ctx && ctx.getImage(n.url);
            if(imageData) {
                return new ImageRun({
                    data: imageData as any,
                    transformation: {
                        width: (n.width || "50").replace(/[^0-9]/g, ""),
                        height: (n.height || "50").replace(/[^0-9]/g, ""),
                    },
                });
            } else {
                return g([{ text: "" }], s, ctx);
            }
        },
    },
    {
      // TEXT / LEAFS
        shallProcess: n => !Element.isElement(n),
        process: (n, g) => {
            const lines = (n.text as string || "").split("\n");
            if(lines.length === 1) {
                return new TextRun({
                    text: n.text,
                    bold: n.bold,
                    italics: n.italic,
                    underline: n.underline,
                    strike: n.strikethrough,
                    highlight: n.highlight ? "yellow" : undefined,
                });
            }
            return lines.map((l,idx) => new TextRun({
                text: l,
                break: idx !== 0 ? 1 : undefined,
                bold: n.bold,
                italics: n.italic,
                underline: n.underline,
                strike: n.strikethrough,
                highlight: n.highlight ? "yellow" : undefined,
            }));
        }
    },
    {
      // HEADINGS
        shallProcess: n => /h\d/.test((n as any).type || ""),
        process: (n, g, s, ctx) => new Paragraph({
            children: g(n.children || [], s, ctx),
            heading: HeadingLevels[+(n as any).type.substring(1)],
            ...commonBlockProperties(n),
            indent: (n as any).no_indent || s?.no_indent ? { start: 0, firstLine: 0 } : undefined,
        }),
    }
];

const processNodes = (nodes: any[], settings: any, ctx: ProcessingContext) => {
    return nodes.reduce((r,n) => {
        const processor = ctx.customProcessors?.find(p => p.shallProcess(n)) || processors.find(p => p.shallProcess(n));
        if(processor) {
            const resultNodesOrNode = processor.process(n, processNodes, settings, ctx);
            if(resultNodesOrNode) {
                if(Array.isArray(resultNodesOrNode)) {
                    resultNodesOrNode.forEach(n => r.push(n));
                } else {
                    r.push(resultNodesOrNode);
                }
            }
        } else {
            console.log("DOCX - didn't find processor for node", n);
        }
        return r;
    }, []);
}

export const collectNodes = (nodes: any[], check: (node: any) => boolean): any[] => {
    return nodes.reduce((r,n) => {
        if(check(n)) {
            r.push(n);
        }
        const children = collectNodes(n.children || [], check);
        children.forEach(c => r.push(c));
        return r;
    }, []);
}


export const generateDocx = (ctx: ProcessingContext, content: SlateDescendant[], dotxTemplate?: Blob | null, sectionProperties?: ISectionPropertiesOptions) => {
    const extractTemplate = (): Promise<null | IDocumentTemplate> => {
        if(dotxTemplate) {
            const templateImporter = new ImportDotx();
            return templateImporter.extract(dotxTemplate);
        } else {
            return Promise.resolve(null);
        }
    }

    return extractTemplate()
        .then(dotxTemplate => {
            return new Document({
                numbering: {
                    config: [NumberingConfig],
                },
                sections: [
                    {
                        properties: {
                            titlePage: dotxTemplate?.titlePageIsDefined,
                            ...(sectionProperties || {}),
                        },
                        children: processNodes(content, undefined, ctx),
                    },
                ],
            }, {
                template: dotxTemplate || undefined,
            });
        });
}