import React from 'react';
import { useRobudPrograms } from './useRobudPrograms';
import { FormControlProps } from '../schemed/FormControlProps';
import { IconButton } from '@mui/material';
import { CallMadeOutlined } from '@mui/icons-material';
import { FormControl } from '../schemed';

interface Props extends FormControlProps {
  renderPlaceholderWhenUnavailable?: boolean;
}

export const RobudProgramSelector = (props: Props) => {
  const robudPrograms = useRobudPrograms();
  
  if(!robudPrograms.isAvailable) {
    return props.renderPlaceholderWhenUnavailable ? <div /> : null;
  }

  const schema = { ...props.schema, ...robudPrograms.selectorSchema };
  const selectedProgramId = (props.row || {})[props.field];

  return (
    <FormControl
      {...props}
      schema={schema}
      extraProps={{
        ...(props.extraProps || {}),
        controlProps: {
          ...(props.extraProps?.controlProps || {}),
          startAdornment: (
            <IconButton
              size="small"
              href={selectedProgramId ? `/system/robud/program/${selectedProgramId}` : `/system/robud/program`}
              target="_blank"
              rel="noreferrer noopener">
                <CallMadeOutlined />
            </IconButton>)
        }
      }}
      />
  );
}
