import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useLoadedData } from "../../hooks/useLoadedData";
import { FieldSchema, FieldType } from "../../hooks/useSchema";
import { ConfigurableForm } from "./types";

const BaseSelectorSchema: FieldSchema = {
    type: FieldType.select,
    values: [],
    label: "Form",
    label_id: "forms.editor.form_selection_label",
}

interface Config {
    apiPath: string;
    loadAuto?: boolean;
    formsPagesPath?: string;
}

export const useConfigurableFormsSelectionData = (config: Config) => {
    const [doLoad, setDoLoad] = useState<boolean>(config.loadAuto || false);
    const data = useLoadedData<ConfigurableForm[]>(config.apiPath, [], doLoad);

    const formsById = useMemo(() => {
        return data.data.reduce<Record<string, ConfigurableForm>>(
            (r,f) => {
                r[f._id] = f;
                return r;
            },
            {});
    }, [data.data]);

    const selectorSchema: FieldSchema = useMemo(() => ({
        ...BaseSelectorSchema,
        values: data.data.map(f => ({ value: f._id, label: f.title })),
        valueDict: data.data.reduce<Record<string, string>>((r,f) => {
            r[f._id] = f.title;
            return r;
        }, {}),
    }), [data.data]);

    return {
        ...data,
        formsById,
        selectorSchema,
        ensureLoaded: () => setDoLoad(true),
    }
}

export type ConfigurableFormsSelection = ReturnType<typeof useConfigurableFormsSelectionData> & Pick<Config, "formsPagesPath">;

const ConfigurableFormsSelectionContext = React.createContext<ConfigurableFormsSelection>({
    data: [],
    formsById: {},
    isLoading: true,
    reload: () => {},
    setData: () => {},
    ensureLoaded: () => {},
    selectorSchema: BaseSelectorSchema,
});

export const useConfigurableFormsSelection = () => useContext(ConfigurableFormsSelectionContext);

export const ConfigurableFormsSelectionProvider = (props: PropsWithChildren<Config>) => {
    const { children, ...config } = props;
    const data = useConfigurableFormsSelectionData(config);
    return <ConfigurableFormsSelectionContext.Provider value={{ ...data, formsPagesPath: props.formsPagesPath }}>
        {children}
    </ConfigurableFormsSelectionContext.Provider>
}

