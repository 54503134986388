import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SimpleList } from '../primitives';
import { CreateConfigurableFormPopup } from './CreateConfigurableFormPopup';
import { ConfigurableFormsConfig } from './types';
import { useConfigurableFormsList } from './useConfigurableFormsList';

interface Props extends ConfigurableFormsConfig {
    
}

export const ConfigurableFormsList = (props: Props) => {
    const data = useConfigurableFormsList(props.apiPath);  
    const history = useHistory();
    let { path } = useRouteMatch();

    return (<>
        <SimpleList
            title={<FormattedMessage id="forms.page.list_title" />}
            data={data}
            schema={data.schema}
            getLinkValue={n => `${path}${n._id}`}
            fields={[
                ["title"],
                ["is_active"],
            ]}
            linkFields={["title"]}
            searchAuto
            searchAutoFocus
            />
        <CreateConfigurableFormPopup
            {...data.newItem}
            save={() => {
                return data.newItem.save()
                    .then(n => {
                        history.push(`${path}${n._id}`);
                        return n;
                    });
            }}
            schema={data.schema}
            />
    </>);
}

