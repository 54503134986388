import React from 'react';
import { CMSMenu } from "../toolympus/components/CMS";
import { forAny, setting, suggest } from '../toolympus/components/PowerDoc/PowerEditorSettingsSuggestions';
import { PowerEditorSettingsProvider } from '../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { useMediaFilesPlugin } from '../toolympus/components/PowerDoc/plugins/MediaFiles';
import { PowerEditorBase, PowerEditorBaseProps } from '../toolympus/components/PowerDoc';
import { ColumnsPlugin } from '../toolympus/components/PowerDoc/plugins/Columns';
import { CustomFieldsBlockPlugin } from '../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { ExpanderPlugin } from '../toolympus/components/PowerDoc/plugins/Expander';
import { PanelPlugin } from '../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin';
import { ScriptPlugin } from '../toolympus/components/PowerDoc/plugins/Script';
import { TablesPlugin } from '../toolympus/components/PowerDoc/plugins/Tables';
import { TilesPlugin } from '../toolympus/components/PowerDoc/plugins/Tiles';
import { useLinkedRecordsEditorPlugin } from '../toolympus/components/CMS';
import { useSub } from './SubProvider';
import { MedialibProviderX } from './Medialib';
import { LoadingIndicator } from '../toolympus/components/primitives';


const SettingsSuggestions = [
  suggest(forAny(),
    setting("_class")),
  suggest(forAny(),
    setting("display_type")),
];

export const CMS = () => {
  const { sub } = useSub();
  return (
    <MedialibProviderX>
      <PowerEditorSettingsProvider
          EditorComponent={CMSEditorUnwrapped}
          settingsSuggestions={SettingsSuggestions}>

          {sub
            ? <CMSMenu apiPath={`/api/${sub}/cms/`} includeMediaLib />
            : <LoadingIndicator />}

      </PowerEditorSettingsProvider>      
    </MedialibProviderX>
  );
}


export const CMSEditorUnwrapped = (props: PowerEditorBaseProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();
  const linkedRecordsPlugin = useLinkedRecordsEditorPlugin();

  return (
      <PowerEditorBase
          {...props}
          plugins={[
              mediaFilesPlugin,
              CustomFieldsBlockPlugin,
              TilesPlugin,
              ColumnsPlugin,
              PanelPlugin,
              ScriptPlugin,
              ExpanderPlugin,
              TablesPlugin,
              linkedRecordsPlugin,
          ]}
          />
  );
}
