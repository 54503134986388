import React from 'react';
import styled from '@emotion/styled';
import { IconButton, Paper, Typography } from '@mui/material';
import { FileCopyOutlined, KeyboardReturn } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useItemWithControls } from '../../api/useWithControls';
import { LocaleSwitch } from '../LocalizedRecords';
import { DeleteButton, Form, FormGrid, OccupyFreeSpace, SaveButton, TabPanel, TabsHeader, useTabsState } from '../primitives';
import { ConfigurableFormSubmissions } from './ConfigurableFormSubmissions';
import { FormFieldsEditor } from './FormFieldsEditor';
import { ConfigurableForm, ConfigurableFormsConfig } from './types';
import { useConfigurableFormSubmissions } from './useConfigurableFormSubmissions';
import { useEditConfigurableForm } from './useEditConfigurableForm';
import { useSaveable } from '../primitives/useSaveable';
import { RobudProgramSelector } from '../Robud/RobudProgramSelector';
import { ActionsListEditor } from '../Actions';
import { ActionsConfigProvider } from '../Actions/ActionsConfigProvider';

const ActionsPanel = styled(Paper)`
  margin: 1rem 0.25rem 0.25rem;
  padding-top: 0.5rem;

  & > p:first-child {
    margin-left: 1rem;
  }
`;

interface Props extends ConfigurableFormsConfig {
    
}

export const ConfigurableFormForm = (props: Props) => {
    const { id } = useParams<{ id: string }>();
    const data = useEditConfigurableForm(props.apiPath, id, { localization: props.localization });
    let { path } = useRouteMatch();
    const listPath = path.replace("/:id", "");
    const history = useHistory();

    const schema = data.schema;
    const controls = useItemWithControls(data, { schema });

    useSaveable({
        hasChanges: data.hasChanges,
        save: data.save,
    });

    const tabs = useTabsState([
        ["config", <FormattedMessage id="forms.page.config" />],
        ["actions", <FormattedMessage id="forms.page.actions" />],
        ["submissions", <FormattedMessage id="forms.page.submissions" />],
    ], "config", "tab");
    
    return (
        <Form
            title={<FormattedMessage id="forms.page.form_page_title" />}
            fitFullHeight
            headerItems={tabs.current === "config" || tabs.current === "actions"
                ? <>
                    <OccupyFreeSpace />
                    <Link to={listPath}>
                        <IconButton size="small">
                            <KeyboardReturn />
                        </IconButton>
                    </Link>
                    {props.localization && <LocaleSwitch locale={data.locale} setLocale={data.setLocale} availableLocales={props.localization.locales} />}
                    <IconButton size="small" onClick={() => data.copyForm().then(n => history.push(`${listPath}/${n._id}`))}>
                        <FileCopyOutlined />
                    </IconButton>
                    {data.hasChanges && <SaveButton action={() => data.save()} />}
                    <DeleteButton
                        size="small"
                        color="secondary"
                        preventGoBack
                        title={<FormattedMessage id="forms.page.delete_confirmation" />}
                        remove={() => data.remove().then(() => history.push(listPath)) }
                        />
                </>
                : <>
                    <Typography style={{ marginLeft: "1rem", marginTop: "0.6em" }}>{data.data.title}</Typography>                    
                </>
            }>

                <TabsHeader state={tabs} noMargin />
                
                <TabPanel state={tabs.forTab("config")} style={{ overflowY: "auto", minHeight: "92%" }}>
                    <FormGrid columns="2fr 1fr" style={{ alignItems: "flex-end"}}>
                        {controls.controls([
                            ["title"],
                            ["is_active"],
                            ["button_label"],
                        ])}

                        <RobudProgramSelector
                          field="on_submission_robud_program"
                          row={data.data}
                          schema={schema["on_submission_robud_program"]}
                          onChange={(o,c) => data.update(c)}
                          renderPlaceholderWhenUnavailable
                          />
                    </FormGrid>

                    <Typography variant="caption" component="p" color="textSecondary" style={{ marginTop: "1rem" }}><FormattedMessage id="forms.form.fields" /></Typography>
                    <FormFieldsEditor data={data.fields} />
                </TabPanel>


                <TabPanel state={tabs.forTab("actions")} style={{ overflowY: "auto", minHeight: "92%" }}>
                  <ActionsConfigProvider>
                    <ActionsPanel>
                      <Typography variant="caption" component="p" color="textSecondary">
                        <FormattedMessage id="forms.field.actions.on_submission" />
                      </Typography>
                      <ActionsListEditor
                        actions={data.data.actions?.on_submission || []}
                        update={v => data.update({ actions: { ...(data.data.actions || {}), on_submission: v }})}
                        />
                    </ActionsPanel>
                    
                    <ActionsPanel>
                      <Typography variant="caption" component="p" color="textSecondary">
                        <FormattedMessage id="forms.field.actions.manual" />
                      </Typography>
                      <ActionsListEditor
                        actions={data.data.actions?.manual || []}
                        update={v => data.update({ actions: { ...(data.data.actions || {}), manual: v }})}
                        />
                    </ActionsPanel>

                  </ActionsConfigProvider>
                </TabPanel>


                <TabPanel state={tabs.forTab("submissions")} style={{ overflowY: "hidden", display: "flex", flexFlow: "column", height: "100%" }}>
                    <SubmissionsTable apiPath={props.apiPath} form={data.data} />
                </TabPanel>
        </Form>
    );
}

// this was introduced to load the data only when the tab is open
// in particular to avoid the infinite update->rerender->update submissions default fields->rerender->update submissions default fields loop
// (see one of the useEffects in useConfigurableFormSubmissions)
const SubmissionsTable = (props: { apiPath: string, form: ConfigurableForm }) => {
  const submissions = useConfigurableFormSubmissions(props.apiPath, props.form, true);

  return <ConfigurableFormSubmissions data={submissions} />;
}
