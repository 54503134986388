import React, { ReactNode } from 'react';
import { ItemEditorProps, ListEditor } from './ListEditor';
import { generateCode } from '../PowerDoc/plugins/common';
import { FormGrid } from '.';
import { FormControlsForFields } from '../schemed';

export interface MenuItem {
  _id: string;
  url: string;
  title: string;
}

interface Props {
  menu: MenuItem[];
  update: (v: MenuItem[]) => void;
  label?: ReactNode;
}

const BasicMenuItemEditor = (props: ItemEditorProps<MenuItem>) => {
  return (
    <FormGrid columns="1fr 1fr">
      <FormControlsForFields
        fields={[
          ["title"],
          ["url"],
        ]}
        data={props.item}
        onChange={(o,c) => props.update(c)}
        schema={{ title: { label_id: "common.menu.title" }, url: { label_id: "common.menu.url" }}}
        />
    </FormGrid>
  )
}

export const BasicMenuEditor = (props: Props) => {
  return (
    <div>
      {props.label}
      <ListEditor
        idField="_id"
        items={props.menu}
        update={v => props.update(v)}
        createDflt={() => ({ _id: generateCode(), url: "", title: "" })}
        itemEditor={BasicMenuItemEditor}
        />
    </div>
  );
}
