import { useState } from "react";
import { apiFetch } from "../../api/core";
import { CrudItemData, useCrudItem } from "../../api/useSimpleCrud"
import { useEditArray } from "../../hooks/useEditArray";
import { useSingleSchema } from "../../hooks/useSchema";
import { useLocalizedRecord } from "../LocalizedRecords";
import { generateCode } from "../PowerDoc/plugins/common";
import { ConfigurableForm, ConfigurableFormsConfig, FormField } from "./types";
import { useEditItem2 } from "../../api/useNewItem";

interface Config extends Pick<ConfigurableFormsConfig, "localization"> {

}

export const useEditConfigurableForm = (apiPath: string, id: string, config: Config) => {
    const data = useCrudItem<ConfigurableForm>(`${apiPath}/${id}`, {
        defaultValue: { fields: [] } as any as ConfigurableForm,
        noLoad: id === undefined,
    });

    const { schema } = useSingleSchema(`${apiPath}/uiconfig`);

    const localized = useLocalizedRecord(data.data, {
        defaultLocale: config.localization?.defaultLocale || "xx",
        translatedFields: [
            "title",
            "button_label",
        ],
        translationsField: "translations",
        updateItem: (o,c) => data.update(c),
    });

    const { locale, setLocale } = localized;

    const fields = useEditFormFields(data, localized);

    const copyForm = () => {
        const copy = { ...data.data };
        delete (copy as any)._id;
        delete copy.files_api_path;
        copy.title = `${copy.title} (copy)`;
        return apiFetch<ConfigurableForm>(
            apiPath,
            "post",
            copy);
    }

    return {
        ...data,
        data: localized.item,
        update: (c: Partial<ConfigurableForm>) => localized.updateItem(localized.item, c),
        copyForm,
        fields,
        locale,
        setLocale,
        schema,
    }
}

export const useEditFormFields = (data: CrudItemData<ConfigurableForm>, localization: { locale: string, isDefaultLocale: boolean }) => {
    const { locale, isDefaultLocale } = localization;

    const fields = useEditArray<FormField>({
        items: data.data.fields,
        update: fields => data.update({ fields }),
        dflt: {
            _id: generateCode(),
            fieldtype: "text",
            title: "",
        },
    });

    const [insertedFieldId, setInsertedFieldId] = useState<string>("");

    const add = () => {
        const id = generateCode();
        setInsertedFieldId(id);
        return fields.add({
            _id: id,
            fieldtype: "text",
            title: "",
        });
    }

    const update = (idx: number, changes: Partial<FormField>) => {
        if(isDefaultLocale) {
            fields.update(idx, changes);
        } else {
            const thisField = fields.items[idx];
            const localeTranslations = data.data.translations[locale] || {};
            const fieldsTranslations = localeTranslations["fields"] || {};
            const thisFieldTranslations = fieldsTranslations[thisField._id];
            
            data.update({ translations: {
                ...(data.data.translations || {}),
                [locale]: {
                    ...localeTranslations,
                    fields: {
                        ...fieldsTranslations,
                        [thisField._id]: {
                            ...thisFieldTranslations,
                            ...changes,
                        }
                    }
                },
            }})
        }
    }

    const editFieldId = useEditItem2<FormField & { oldId: string }>({
      getApiPath: () => "",
      save: item => {
        data.update({ fields: data.data.fields.map(f => f._id === item.oldId ? { ...f, _id: item._id } : f)});
        return Promise.resolve(item);
      }
    });

    const items = isDefaultLocale
        ? fields.items
        : fields.items.map(f => {
            const translation = ((data.data.translations[locale] || {})["fields"] || {})[f._id] || {};
            
            return {
                ...f,
                ...translation,
                _id: f._id,
                fieldtype: f.fieldtype,
            }
        });

    return {
        ...fields,
        insertedFieldId,
        items,
        add,
        update,
        errors: data.errors,
        locale,
        isDefaultLocale,
        editFieldId,
    }
}

export type FormFieldsData = ReturnType<typeof useEditFormFields>;


export const useEditFormFieldsBasic = <T extends { fields: FormField[] }>(data: CrudItemData<T>): FormFieldsData => {
  const fields = useEditArray<FormField>({
      items: data.data.fields,
      update: fields => data.update({ fields } as Partial<T>),
      dflt: {
          _id: generateCode(),
          fieldtype: "text",
          title: "",
      },
  });

  const [insertedFieldId, setInsertedFieldId] = useState<string>("");

  const add = () => {
      const id = generateCode();
      setInsertedFieldId(id);
      return fields.add({
          _id: id,
          fieldtype: "text",
          title: "",
      });
  }

  const update = (idx: number, changes: Partial<FormField>) => {
    fields.update(idx, changes);
  }

  const editFieldId = useEditItem2<FormField & { oldId: string }>({
    getApiPath: () => "",
    save: item => {
      data.update({ fields: data.data.fields.map(f => f._id === item.oldId ? { ...f, _id: item._id } : f) } as Partial<T>);
      return Promise.resolve(item);
    }
  });

  const items = fields.items

  return {
      ...fields,
      insertedFieldId,
      items,
      add,
      update,
      errors: data.errors,
      locale: "",
      isDefaultLocale: true,
      editFieldId,
  }
}
