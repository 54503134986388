import React from "react";
import { AssignmentOutlined, PrintOutlined, Settings, TranslateOutlined } from "@mui/icons-material";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { CMS } from "./components/CMS";
import { LocalizedMessagesX } from "./components/LocalizedMessages";
import { SettingsGeneral } from "./components/Settings";
import { Forms } from "./components/Forms/Forms";

const isAdmin = (roles: string[]) => roles.includes("admin");

const InnerRoutes: MenuRoute[] = [
  {
    path: "/settings",
    title: "Settings",
    icon: <Settings />,
    component: SettingsGeneral,
  },
  {
    path: "/cms",
    title: "CMS",
    icon: <PrintOutlined />,
    component: CMS,
  },
  {
    path: "/forms",
    title: "Forms",
    icon: <AssignmentOutlined />,
    component: Forms,
  },
  {
    path: "/messages",
    title: "Messages",
    icon: <TranslateOutlined />,
    component: LocalizedMessagesX,
  },
  { 
      path: '/system',
      title: 'System',
      resolveHidden: isAdmin,
      icon: <Settings />,
      component: () => (
          <SystemSubmenu
              emails={{ apiPath: "/emails" }}
              users={{ allowInvites: true, allowPaswordReset: true, allowPaswordChange: true, twoFactorAuthEnabled: true }}
              pages={[{
                  apiPath: "/api/page",
                  key: "all",
                  label: "All",
              }]}
              apiTokens={{ apiPath: "/api/apitoken" }}
          />),
  },
];

export default InnerRoutes;