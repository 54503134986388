import { useMemo } from "react";
import { useSlateStatic } from "slate-react";
import { useLoadedData } from "../../../hooks/useLoadedData";
import { FieldType, Schema } from "../../../hooks/useSchema";
import { usePowerEditorContext } from "../../PowerDoc/slate/PowerEditorContext";
import { useCMSConfiguration } from "../Configuration/CMSConfigurationContext";
import { CMSRecord } from "../types";
import { useCMSMenu } from "../useCMSMenu";
import { LinkedRecordsSettings, StartSelectingLinkedRecords, UpdateLinkedRecordsProps } from "./types";

export const useLinkedRecordsData = (element: LinkedRecordsSettings) => {
    const { apiPath } = useCMSConfiguration();
    const rts = useCMSMenu(apiPath);
    const recordsData = useLoadedData<CMSRecord[]>(
        `${apiPath}/manage/${element.record_type}?ids=${(element.records_ids || []).join(",")}`,
        [],
        !!element.record_type && !!element.records_ids && element.records_ids.length > 0);


    const selectedRecords = useMemo(() => {
        const ids = element.records_ids || [];
        return ids.map(id => recordsData.data.find(r => r._id === id));
    }, [recordsData.data, element.records_ids]);

    const { invokeCommand } = usePowerEditorContext();
    const editor = useSlateStatic();


    const update = (changes: Partial<LinkedRecordsSettings>) => {
        const changesCopy = { ...changes };
        if(changes.record_type === null) {
            changesCopy.record_type = "";
        }
        if(changesCopy.record_type !== element.record_type) {
            changesCopy.records_ids = [];
        }
        invokeCommand(UpdateLinkedRecordsProps, editor, element as any, changesCopy);
    }

    const rtSchema: Schema = useMemo(() => ({
        record_type: {
            type: FieldType.select,
            values: rts.data.map(rt => ({ label: rt.label || rt.record_type, value: rt.record_type })),
            label: "Record type",
            label_id: "cms.importexport.record_type",
        },
    }), [rts.data]);

    const startSelectingRecods = () => {
        invokeCommand(StartSelectingLinkedRecords, editor, element as any, element);
    }

    return {
        isRecordTypesLoading: rts.isLoading,
        recordTypes: rts.data,
        schema: rtSchema,
        
        selectedRecords,
        localState: {
            record_type: element.record_type || "",
            records_ids: element.records_ids || [],
        },
        update,

        startSelectingRecords: startSelectingRecods,
    }
}
