import React, { DragEventHandler, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { RenderElementProps, useSelected } from "slate-react";
import { FormGrid } from '../../primitives';
import { BlockSelectionCss } from '../../PowerDoc/elements/Common';
import { Avatar, Chip, IconButton, Typography } from '@mui/material';
import { useLinkedRecordsData } from './useLinkedRecordsData';
import { LinkedRecordsSettings } from './types';
import { FormControl } from '../../schemed';
import { FormattedMessage } from 'react-intl';
import { CallMade, EditOutlined } from '@mui/icons-material';
import { CMSRecord } from '../types';
import { Link } from 'react-router-dom';
import { useCMSConfiguration } from '../Configuration/CMSConfigurationContext';

const Wrapper = styled(FormGrid)<{ isSelected?: boolean }>`
    width: 100%;
    padding: 0.5rem 1rem;
    border-top: 2px solid #eeeeee;
    border-bottom: 2px solid #eeeeee;
    ${props => BlockSelectionCss(props)}
`;


const Placeholder = styled.div`
    background: ${props => props.theme.palette.grey[100]};
    padding: 1rem 0.5rem;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
`;

const RecordsWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
`;

export const recordLabel = (r: CMSRecord) => `${r.title || ""} (${r.slug || ""} ${r._id || ""})`;

interface RecordChipProps {
  record: CMSRecord | undefined;
  isDraggingRecord?: boolean;
  onDelete?: () => void;
  onDragStart?: DragEventHandler<HTMLDivElement>;
  onDragEnd?: DragEventHandler<HTMLDivElement>;
  onDrop?: DragEventHandler<HTMLDivElement>;
  isDragging?: boolean;
}

const ChipWrapper = styled.div<{ isDropTarget?: boolean }>`
  position: relative;

  &:before {
    display: ${props => props.isDropTarget ? "block" : "none"};
    position: absolute;
    left: -0.25rem;
    top: 50%;
    transform: translate(-2px,-50%);
    content: ' ';
    width: 1px;
    height: 80%;
    border-left: 4px solid ${props => props.theme.palette.primary.main};
    opacity: 0.5;
  }
`;

export const RecordChip = ({ record, onDelete, isDraggingRecord, onDragStart, onDragEnd, onDrop }: RecordChipProps) => {
  const { getRecordUrl } = useCMSConfiguration();
  const [isDropTarget, setIsDropTarget] = useState<boolean>(false);

  useEffect(() => {
    if(!isDraggingRecord) {
      setIsDropTarget(false);
    }
  }, [isDraggingRecord]);

  return (
    <ChipWrapper
      className="record-chip"
      isDropTarget={isDropTarget}
      draggable={!!onDragStart}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragEnter={e => {
        e.preventDefault();
        e.stopPropagation();
        if(isDraggingRecord) {
          setIsDropTarget(true);
        }
      }}
      onDragOver={e => {
        e.preventDefault();
        e.stopPropagation();
        if(isDraggingRecord) {
          setIsDropTarget(true);
        }
      }}
      onDragLeave={() => {
        if(isDraggingRecord) {
          setIsDropTarget(false);
        }
      }}
      onDrop={onDrop}>
      <Chip
        label={record ? recordLabel(record) : ""}
        onDelete={onDelete}
        avatar={<Avatar>
            <Link to={record ? getRecordUrl(record) || "" : ""} onClick={e => { e.stopPropagation(); }}>
              <IconButton style={{ transform: "scale(0.75)"}} size="small"><CallMade /></IconButton>
            </Link>
          </Avatar>}
        />
    </ChipWrapper>
  );
}

export const LinkedRecordsBlockElement = (props: RenderElementProps) => {
    const data = useLinkedRecordsData(props.element as any as LinkedRecordsSettings);
    

    const isSelected = useSelected();

    return (
        <div {...props.attributes}>
            {props.children}

            <Wrapper contentEditable={false} columns="1fr" isSelected={isSelected}>
                <FormControl
                    field="record_type"
                    schema={data.schema.record_type}
                    onChange={(o,c) => data.update(c)}
                    row={data.localState}
                    />
                {data.localState.records_ids.length === 0 &&
                    <Placeholder onClick={() => data.startSelectingRecords()}>
                        <Typography><FormattedMessage id="cms.editor.linked_records_empty" /></Typography>
                    </Placeholder>}
                {data.selectedRecords.length > 0 &&
                    <RecordsWrapper onClick={() => data.startSelectingRecords()}>
                        {data.selectedRecords.map(r => <RecordChip key={r?._id} record={r} />)}

                        <IconButton size="small" color="primary"><EditOutlined /></IconButton>
                    </RecordsWrapper>}
            </Wrapper>
        </div>
    );
}
