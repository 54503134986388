import { useState } from "react";
import { apiFetch } from "../../api/core";
import { ConfigurableForm, FormSubmission } from "./types";

export const useConfigurableFormSubmissionReview = (apiPath: string, form: ConfigurableForm) => {
    const [item, setItem] = useState<FormSubmission | null>(null);
    const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
    const [isUpdatingComment, setIsUpdatingComment] = useState<boolean>(false);

    const realApiPath = `${apiPath.replace("/config", "")}/${form._id}/submission/${item?._id}`;

    const open = (submission: FormSubmission) => {
        setItem(submission);
    }

    const close = () => {
        setItem(null);
    }

    const updateStatus = (newStatus: string) => {
        if(item) {
            setIsUpdatingStatus(true);
            const origStatus = item.processing_status;
            setItem(({ ...item, processing_status: newStatus }));
            apiFetch(realApiPath, "put", { processing_status: newStatus })
                .then(() => {
                    setIsUpdatingStatus(false);
                })
                .catch(e => {
                    setIsUpdatingStatus(false);
                    setItem({ ...item, processing_status: origStatus });
                    throw e;
                });
        }
    }

    const [commentUpdatingTimeout, setCommentUpdatingTimeout] = useState<any>(null);

    const updateComment = (newComment: string) => {
        if(item) {
            const origComment = item.comment;
            setItem(({ ...item, comment: newComment }));

            if(commentUpdatingTimeout) {
                clearTimeout(commentUpdatingTimeout);
            }
            const timeout = setTimeout(() => {
                setIsUpdatingComment(true);
                apiFetch(realApiPath, "put", { comment: newComment })
                    .then(() => {
                        setIsUpdatingComment(false);
                    })
                    .catch(e => {
                        setIsUpdatingComment(false);
                        setItem({ ...item, comment: origComment });
                        throw e;
                    });
            }, 2000);
            setCommentUpdatingTimeout(timeout);
        }
    }

    return {
        item,
        isOpen: !!item,
        close,
        open,

        isUpdating: isUpdatingComment || isUpdatingStatus,
        updateStatus,
        updateComment,
    }

}

export type ConfigurableFormSubmissionReviewData = ReturnType<typeof useConfigurableFormSubmissionReview>;
