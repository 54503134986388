import React, { PropsWithChildren, useContext, useState } from 'react';


export interface ISubContext {
  sub: string;
  setSub: (sub: string) => void;
}

const SubContext = React.createContext<ISubContext>({ sub: "", setSub: () => {}});

export const useSub = () => useContext(SubContext);

export const SubProvider = (props: PropsWithChildren<{}>) => {
  const [sub,setSub] = useState<string>("");
  return (
    <SubContext.Provider value={{ sub, setSub }}>
      {props.children}
    </SubContext.Provider>
  );
}
