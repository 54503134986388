import React from 'react';
import { useSub } from '../SubProvider';
import { ConfigurableFormsRoutes } from '../../toolympus/components/ConfigurableForms';
import { LoadingIndicator } from '../../toolympus/components/primitives';

interface Props {
  
}

export const Forms = (props: Props) => {
  const { sub } = useSub();
  return (<>
    {sub
      ? <ConfigurableFormsRoutes
          apiPath={`/api/${sub}/form/config`}/>
      : <LoadingIndicator />}
  </>);
}
