import { useNewItem } from "../../api/useNewItem";
import { useCrudUnpagedList } from "../../api/useSimpleCrud";
import { useSingleSchema } from "../../hooks/useSchema";
import { ConfigurableForm, CreateConfigurableForm } from "./types";

export const useConfigurableFormsList = (apiPath: string) => {
    const data = useCrudUnpagedList<ConfigurableForm>(apiPath, {
        filterBy: f => f.title,
    });

    const newItem = useNewItem<CreateConfigurableForm, ConfigurableForm>(apiPath, { title: "", fields: [] });

    const { schema } = useSingleSchema(`${apiPath}/uiconfig`);

    return {
        ...data,
        schema,
        newItem,
        create: () => {
            newItem.startEditing();
            return Promise.resolve({} as ConfigurableForm);
        },
    }
}
