import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider as DatesLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { FormatsProvider } from './toolympus/components/schemed';

import { theme } from './theme';
import { SubProvider } from './components/SubProvider';
import { ConfirmationDialogProvider } from './toolympus/components/primitives';
import { ApiConfgurationProvider } from './toolympus/components/ApiConfguration';

interface Props {
    children?: ReactNode;
}

export const Providers = (props: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst >
                <LocalizationProvider locale="en" defaultLocale="en">
                  <ConfirmationDialogProvider>
                    <FormatsProvider>
                      <ApiConfgurationProvider actionsApiPath="/api/actions">
                        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                          <DatesLocalizationProvider dateAdapter={AdapterMoment}>
                              <SubProvider>
                                {props.children}
                              </SubProvider>
                          </DatesLocalizationProvider>
                        </SnackbarProvider>
                      </ApiConfgurationProvider>
                    </FormatsProvider>
                  </ConfirmationDialogProvider>
                </LocalizationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}
