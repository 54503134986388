import React, { useEffect } from 'react';
import { useSub } from './SubProvider';
import { useLoadedData } from '../toolympus/hooks/useLoadedData';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Dialog, useDialogState } from '../toolympus/components/primitives';
import { Check, Public } from '@mui/icons-material';

const SubLSKey = "_tooly_x_cms_sub";

const useSubSelector = () => {
  const { sub, setSub: setSubX } = useSub();

  const setSub = (s: string) => {
    setSubX(s);
    localStorage.setItem(SubLSKey, s);
  }

  const { isLoading, data: available } = useLoadedData<string[]>("/api/space", []);

  useEffect(() => {
    if(!sub && !isLoading && available.length) {
      const fromLS = localStorage.getItem(SubLSKey);
      setSub(fromLS && available.includes(fromLS) ? fromLS : available[0]);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, available, sub]);

  return {
    sub,
    setSub,
    available,
    isLoading,
  }
}

export const SubSelector = () => {
  const selector = useSubSelector();
  const dialog = useDialogState();
  return (
    <>
      <ListItem
        key='space'
        button
        onClick={selector.available.length ? () => dialog.open() : undefined}>
          <ListItemIcon><Public /></ListItemIcon>
          <ListItemText primary={selector.sub || "select sub"} />
      </ListItem>

      <Dialog
        {...dialog}
        maxWidth="sm"
        fullWidth
        noFullscreen
        dialogTitle="Select space">
          <List>
            {selector.available.map(space => (
              <ListItem
                button
                onClick={() => selector.setSub(space)}>
                  <ListItemIcon color="primary">{selector.sub === space ? <Check /> : undefined}</ListItemIcon>
                  <ListItemText primary={space} />
              </ListItem>
            ))}
          </List>
      </Dialog>
    </>
  );
}
