import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { Schema } from '../../hooks/useSchema';
import { DeleteButton, Dialog, DownloadFileLink, FormGrid, LoadingIndicator } from '../primitives';
import { FormControl, FormControlsForFields } from '../schemed';
import { ConfigurableForm, FormSubmission } from './types';
import { ConfigurableFormSubmissionReviewData } from './useConfigurableFormSubmissionReview';
import { Typography } from '@mui/material';

interface Props {
    data: ConfigurableFormSubmissionReviewData;
    schema: Schema;
    fields: string[];
    form: ConfigurableForm;
    remove: (s: FormSubmission) => Promise<void>;
    downloadFormFile: (fileId: string) => Promise<void>;
}

const FileControl = styled.div`
    & > :first-child {
        display: block;
    }
`;

export const ConfigurableFormSubmissionReviewPopup = (props: Props) => {
    const { data, fields, form, schema, remove } = props;

    return (
        <Dialog
            isOpen={data.isOpen}
            close={data.close}
            dialogTitle={<FormattedMessage id="forms.page.review_title" />}
            titleActions={<>
                {data.isUpdating && <LoadingIndicator sizeVariant="s" />}
                <DeleteButton
                  title={<FormattedMessage id="forms.page.delete_submission_confirmation" />}
                  size="small"
                  preventGoBack
                  remove={() => data.item ? remove(data.item).then(() => { data.close(); return {}}) : Promise.resolve({})}
                  />
            </>}
            noFullscreen>

            {data.item && <FormGrid columns="3fr 1fr">
                <FormControlsForFields
                    data={data.item}
                    schema={schema}
                    fields={[
                        ["created_at", { utcToLocal: true }],
                        ["lang"],
                    ]}
                    onChange={() => {}}
                    readOnly
                    />
                <FormControlsForFields
                    data={data.item}
                    schema={schema}
                    fields={[
                        ["comment", { autoRows: true }],
                    ]}
                    onChange={(o,c) => data.updateComment(c.comment)}
                    />
                <FormControlsForFields
                    data={data.item}
                    schema={schema}
                    fields={[
                        ["processing_status", { labelIdPrefix: "forms.submission.processing_status_value" }],
                    ]}
                    onChange={(o,c) => data.updateStatus(c.processing_status)}
                    />
            </FormGrid>}
            
            {data.item && <FormGrid columns="1fr">
                {fields.map(f => {
                    const field = form.fields.find(field => field._id === f);
                    
                    if(field?.fieldtype === "file" && form.files_api_path) {
                        return <FileControl>
                            <Typography variant="caption" color="textSecondary">
                                {field.title}
                            </Typography>
                            <Typography>
                                {data.item?.fields[f]
                                    ? <DownloadFileLink downloadFile={() => props.downloadFormFile(data.item?.fields[f])}>
                                        <FormattedMessage id="forms.submission.download" />
                                    </DownloadFileLink>
                                    : "-"}
                            </Typography>
                        </FileControl>
                    }
                    return (
                        <FormControl
                            field={f}
                            row={data.item?.fields || {}}
                            onChange={() => {}}
                            schema={schema[f] || {}}
                            extraProps={{ readOnly: true, autoRows: true }}
                            />
                    )
                })}
            </FormGrid>}
        </Dialog>
    );
}
