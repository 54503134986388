import React from 'react';
import { useSub } from './SubProvider';
import { LocalizationMessagesContainer } from '../toolympus/components/localization';
import { LoadingIndicator } from '../toolympus/components/primitives';

interface Props {
  
}

export const LocalizedMessagesX = (props: Props) => {
  const { sub } = useSub();
  return sub
    ? <LocalizationMessagesContainer apiPath={`/api/${sub}/localization/messages`} />
    : <LoadingIndicator />;
}
