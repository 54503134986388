import React from 'react';
import { useSub } from '../SubProvider';
import { SettingsEpamg } from './SettingsEpamg';

interface Props {
  
}

export const SettingsGeneral = (props: Props) => {
  const { sub } = useSub();

  switch(sub) {
    case "epamg":
      return <SettingsEpamg />
    default:
      return (
        <div>
          There are no settings for this space.  
        </div>
      );
  }
}
