import { useMemo, useState } from "react"
import { ReactEditor } from "slate-react";
import { Element as SlateElement } from 'slate';
import { useLoadedData } from "../../../hooks/useLoadedData";
import { CMSRecord } from "../types";
import { LinkedRecordsSettings } from "./types"
import { useTextFilter } from "../../schemed/Filtering/useTextFilter";
import { useCMSRecordContext } from "../Forms/CMSRecordContext";
import { moveArrayItemTo } from "../../../hooks/useEditArray";

interface State extends LinkedRecordsSettings {
    editor?: ReactEditor;
    element?: SlateElement;
}

export const useLinkedRecordsSelectionData = (
    apiPath: string,
    onFinishSelection: (e: ReactEditor, element: SlateElement, records_ids: number[]) => void) => {

    const { lang } = useCMSRecordContext();
    const [data, setData] = useState<State>({ record_type: null, records_ids: [] });
    const recordsData = useLoadedData<CMSRecord[]>(`${apiPath}/manage/${data.record_type}?${lang ? `lang=${lang}` : ""}`, [], !!data.record_type);

    const filter = useTextFilter<CMSRecord>(r => `${r.title} ${r.slug} ${r._id}`);

    const selectedRecords = useMemo(() => {
        const recs = recordsData.data.filter(r => data.records_ids.includes(r._id));
        return data.records_ids.map(id => recs.find(r => r._id === id));
    }, [data.records_ids, recordsData]);

    const close = () => {
        setData({ record_type: null, records_ids: [] });
    }

    const selectRecord = (record: CMSRecord) => {
        if(data.record_type) {
            setData(x => ({
                ...x,
                records_ids: [...x.records_ids, record._id],
            }));
        }
    }

    const unselectRecord = (record: CMSRecord) => {
        if(data.record_type) {
            setData(x => ({
                ...x,
                records_ids: x.records_ids.filter(r => r !== record._id),
            }));
        }
    }

    const isRecordSelected = (record: CMSRecord) => data.records_ids.includes(record._id);

    const toggleSelectRecord = (record: CMSRecord) => {
        if(data.record_type) {
            setData(x => ({
                ...x,
                records_ids: x.records_ids.includes(record._id) ? x.records_ids.filter(r => r !== record._id) : [...x.records_ids, record._id],
            }));
        }
    }

    const finishSelection = () => {
        if(data.record_type && data.editor && data.element) {
            onFinishSelection(data.editor, data.element, data.records_ids);
        }
        close();
    }

    const moveSelectedRecordTo = (record: CMSRecord, toIdx: number) => {
      setData(x => {
        const oldIdx = x.records_ids.indexOf(record._id);
        return ({
          ...x,
          records_ids: oldIdx >= 0 ? moveArrayItemTo(x.records_ids, oldIdx, toIdx) : x.records_ids,
      })});
    }

    return {
        open: (state: State) => setData(state),
        isOpen: !!data.record_type,
        close,
        data,
        selectedRecords,

        records: filter.filterData(recordsData.data),
        isRecordsLoading: recordsData.isLoading,
        filter,

        isRecordSelected,
        selectRecord,
        unselectRecord,
        toggleSelectRecord,
        moveSelectedRecordTo,
        finishSelection,
    }    
}

export type LinkedRecordsSelectionData = ReturnType<typeof useLinkedRecordsSelectionData>;
